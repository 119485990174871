import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalProps } from "@chakra-ui/react";
import React from "react";
import Profile from "./Profile";

export default function ProfileModal(props: Omit<ModalProps, "children">) {
  return (
    <Modal {...props} >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Profile />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}