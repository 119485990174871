import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Heading, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {Link, useLocation, useMatch} from 'react-router-dom';
import { useAppActions, useAppState, useUser } from "../hooks";
import { login, logout } from "../utils";

function LoginOrLogoutButton() {
  const {user} = useAppState();
  if(user === undefined) 
    return <></>;

  if(user === null)
    return <Button colorScheme="green" onClick={login}>Login</Button>;

  return <Button colorScheme="red" onClick={logout}>Logout</Button>;
}

export default function AppDrawer() {
  const onPicks = useMatch('/picks');
  const onRules = useMatch('/rules');
  const onStandings = useMatch('/standings');
  const onStream = useMatch('/stream');
  const onLedger = useMatch('/ledger');
  const onAdmin = useMatch('/admin');
  const onFantasy = useMatch('/fantasy');
  const { drawerOpen } = useAppState();
  const { toggleDrawer } = useAppActions();
  const location = useLocation();
  const user = useUser();

  useEffect(() => {
    toggleDrawer(false);
  }, [location, toggleDrawer]);

  return (
    <Drawer
      isOpen={drawerOpen}
      placement='left'
      onClose={toggleDrawer}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading size="md">Rugby Pick&apos;em</Heading>
        </DrawerHeader>
        <DrawerBody>
          <VStack>
            <Button colorScheme={onRules ? 'blue' : 'blackAlpha'} w="100%" as={Link} to="/rules">Rules</Button>
            <Button colorScheme={onLedger ? 'blue' : 'blackAlpha'} w="100%" as={Link} to="/ledger">Ledger</Button>
            <Button colorScheme={onPicks ? 'blue' : 'blackAlpha'} w="100%" as={Link} to="/picks">Picks</Button>
            <Button colorScheme={onStandings ? 'blue': 'blackAlpha'} w="100%" as={Link} to="/standings">Standings</Button>
            <Button colorScheme={onFantasy ? 'blue': 'blackAlpha'} w="100%" as={Link} to="/fantasy">Fantasy</Button>
            <Button colorScheme={onStream ? 'blue' : 'blackAlpha'} w="100%" as={Link} to="/stream">Stream</Button>
            { (user?.email === 'edgethio@gmail.com')
              ? (<Button colorScheme={onAdmin ? 'blue' : 'blackAlpha'} w="100%" as={Link} to="/admin">Admin</Button>)
              : ''
            }
          </VStack>
        </DrawerBody>
  
        <DrawerFooter>
          <LoginOrLogoutButton />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}