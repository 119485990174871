import { onAuthStateChanged, User } from "firebase/auth";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import { auth } from '../constants';

export type UserType = User | undefined | null;

export const UserContext = createContext<UserType>(undefined);

type Props = {
    children?: ReactNode,
};

export default function UserContextWrapper({children}:Props) {
  const [user, setUser] = useState<UserType>(undefined);

  useEffect(() => {
    return onAuthStateChanged(auth, (newUser) => {
      setUser(newUser);
    });
  },[]);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
}