import { collection, doc, onSnapshot, query, setDoc, DocumentReference, CollectionReference, getDoc, writeBatch, WriteBatch, Timestamp } from 'firebase/firestore';
import { Game, Ledger, Selection, LedgerEntry, LastWeekWeb, CurrentWeekWeb, GameInfo, Profile } from '@chad/rugby-shared';
import { auth, db } from './constants';
import { validPicks } from './utils';

const pickemCollection = collection(db, 'pickem');
const currentWeekDoc = doc<CurrentWeekWeb, CurrentWeekWeb>(pickemCollection, 'currentWeek') as DocumentReference<CurrentWeekWeb>;
const gamesCollection = collection(currentWeekDoc, 'games');
const ledgerRef = doc<Ledger, Ledger>(pickemCollection, "ledger");
const userLedgerCollection = collection(ledgerRef, 'userLedgers');

export function subscribeToGameUpdates(onUpdate: (update: Game[]) => void) {
  const q = query(gamesCollection);
  return onSnapshot(q, {
    next: (snapshot) => {
      const games = snapshot.docs
        .filter(doc => doc.exists)
        .map(doc => ({
          id: doc.id,
          home: doc.get('home'),
          away: doc.get('away'),
          startTime: doc.get('startTime'),
          link: doc.get('link'),
        } as Game));
      onUpdate(games);
    },
  });
}

export async function savePicks(selections: Record<string, Selection>, games: GameInfo[]) {
  const user = auth.currentUser;
  if(!user)
    throw new Error('Not Logged In');

  const picks = doc(currentWeekDoc, 'picks', user.uid);

  const safeSelections = Object.entries(selections)
    .filter(([,selection]) => selection.amount || selection.team)
    .reduce<Record<string, Selection>>((acc, [key, selection]) => {
      if(!selection.amount)
        delete selection.amount;
      if(!selection.team)
        delete selection.team;
      
      acc[key] = selection;

      return acc;
      
    }, {});
  
  const { valid, problems } = validPicks(safeSelections, games);
  if(!valid) {
    throw new Error(problems.join('\n'));
  }
  
  return setDoc(picks, safeSelections);
}

export function subscribeToPicks(onUpdate: (update: Record<string, Selection>) => void) {
  const user = auth.currentUser;
  if(!user) return () => null;

  const picks = doc(currentWeekDoc, 'picks', user.uid) as DocumentReference<Record<string, Selection>>;

  return onSnapshot(picks, {
    next: (snapshot) => {
      if(snapshot.exists()) {
        onUpdate(snapshot.data());
      }
      else {
        onUpdate({});
      }
    },
  });
}

export function saveProfile(profile: Profile) {
  const user = auth.currentUser;
  if(!user) return Promise.reject("Not logged in");

  const profileDoc = doc(pickemCollection, 'admin', 'profiles', user.uid) as DocumentReference<Profile>;

  return setDoc(profileDoc, profile);
}

export function subscribeToProfiles(onUpdate: (update: Record<string, Profile>) => void) {
  const user = auth.currentUser;
  if(!user) return () => null;

  const profilesCollection = collection(pickemCollection, 'admin', 'profiles') as CollectionReference<Profile>;

  return onSnapshot(profilesCollection, {
    next: (snapshot) => {
      const profiles = snapshot.docs
        .filter(d => d.exists)
        .reduce<Record<string,Profile>>((records, doc) => {
          records[doc.id] = doc.data();
          return records;
        }, {});
      onUpdate(profiles);
    },
  });
}

export function subscribeToCurrentWeek(onUpdate: (update: CurrentWeekWeb|undefined) => void) {
  return onSnapshot<CurrentWeekWeb, CurrentWeekWeb>(currentWeekDoc, {
    next: (doc) => onUpdate(doc.data()),
  });
}

export function subscribeToLedger(onUpdate: (update: Ledger) => void) {
  return onSnapshot<Ledger, Ledger>(ledgerRef, {
    next: (doc) => onUpdate(doc.data() ?? {}),
  });
}

export async function getLastWeek(lastWeekDoc: DocumentReference<LastWeekWeb>) {
  const doc = await getDoc(lastWeekDoc);
  return doc.data();

}
type LedgerEntryRecord = Record<string, LedgerEntry>;
export async function getUserLedger(uid:string) {
  const ref = doc<LedgerEntryRecord, LedgerEntryRecord>(userLedgerCollection, uid);
  const userLedgerDoc = await getDoc(ref);
  return userLedgerDoc.data();

}

function addPayoffToBatch(id:string, amount:number, batch:WriteBatch, updateTime: Date, dateString: string) {
  batch.set(ledgerRef, { [id]: 0 }, { merge: true });
  const userLedger = doc<LedgerEntryRecord, LedgerEntryRecord>(userLedgerCollection, id);
  const entry: LedgerEntry = {
    date: Timestamp.fromDate(updateTime),
    entry: amount * -1,
    type: "PAYMENT",
  };
  
  batch.set(userLedger, { [dateString]: entry }, { merge: true });

  const profile = doc(pickemCollection, 'admin', 'profiles', id);
  batch.set(profile, { agreeFlag: false }, { merge: false });

}

export function payoffUsers(entriesToPayoff: Ledger) {
  const batch = writeBatch(db);
  const updateTime = new Date();
  const dateString = updateTime.getTime().toString() + '-0';


  Object.entries(entriesToPayoff)
    .forEach(([id, amount]) => addPayoffToBatch(id, amount, batch, updateTime, dateString));

  return batch.commit();
}