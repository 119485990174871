import { User } from "firebase/auth";
import React from "react";
import { Avatar, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { logout } from "../utils";
import ProfileModal from "./ProfileModal";

type Props = {
  user: User;
}

export default function UserBadge({ user }: Props) {
  const { displayName, providerData } = user;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const photoUrl = providerData.find((userInfo) => userInfo.photoURL)?.photoURL ?? undefined;

  return (
    <>
      <Menu>
        <MenuButton display="inline-block" cursor="pointer" as={Avatar} name={displayName ?? undefined} src={photoUrl} />
        <MenuList>
          <MenuItem color="black" onClick={logout}>Logout</MenuItem>
          <MenuItem color="black" onClick={onOpen}>Profile</MenuItem>
        </MenuList>
      </Menu>
      <ProfileModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}