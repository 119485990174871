import { Table, Thead, Th, Tr, Td, Tbody, Grid, GridItem, Center, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Bovada } from '@chad/rugby-shared';
import { numberFormat } from '../utils';
import TeamImg from './TeamImg';

export interface PossibleOutcome {
  winners: Bovada.CompetitorName[];
  prob: number;
  payout: number;
}

export interface OutcomeOddsTableProps {
  outcomes: PossibleOutcome[];
}

function toTr([formatedPayout, outcomes]: [string, PossibleOutcome[]]) {
  const last = outcomes[0].winners.length - 1;
  const oddNum = last % 2 === 0;
  const cellOne = outcomes.length === 1
    ? (
      <Grid templateColumns="2.5rem 2.5rem">
        {outcomes[0].winners.map((teamName, index) => (
          <GridItem key={teamName} colSpan={oddNum && index === last ? 2 : 1}>
            <Center><TeamImg maxH="2.5rem" teamName={teamName} /></Center>
          </GridItem>
        ))}
      </Grid>
    )
    : <Text>Multiple</Text>;
  const prob = outcomes.map(({ prob }) => prob).reduce((a, b) => a + b, 0);
  return (
    <Tr key={formatedPayout}>
      <Td>
        {cellOne}
      </Td>
      <Td><Center>{Math.round(prob * 100).toString()}%</Center></Td>
      <Td><Center>{formatedPayout}</Center></Td>
    </Tr>
  );
}

function OutcomeOddsTable({ outcomes }: OutcomeOddsTableProps) {
  const [rows, setRows] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const groups = outcomes.reduce<Record<string, PossibleOutcome[]>>((grp, outcome) => {
      const key = numberFormat(outcome.payout);
      const arr = grp[key] ?? [];
      arr.push(outcome);
      grp[key] = arr;
      return grp;
    }, {});

    const entries = Object.entries(groups);
    entries.sort((a,b) => {
      return b[1][0].payout - a[1][0].payout;
    });
    const newRows = entries.map(toTr);
    setRows(newRows);

  }, [outcomes]);

  return (
    <Table>
      <Thead><Tr>
        <Th>Winners</Th>
        <Th>Probability</Th>
        <Th>Payout</Th>
      </Tr></Thead>
      <Tbody>
        {rows}
      </Tbody>
    </Table>
  );
}

export default OutcomeOddsTable;