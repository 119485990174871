import React, { lazy, Suspense } from 'react';
import UserContextWrapper from './UserContextWrapper';
import { ChakraProvider, extendTheme, Flex } from '@chakra-ui/react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoginBar from './LoginBar';
import AppStateWrapper from './AppStateWrapper';
import AppDrawer from './AppDrawer';
import PossibleOutcomesModal from './PossibleOutcomesModal';

const RulesPage = lazy(() => import('./RulesPage'));
const PicksPage = lazy(() => import('./PicksPage'));
const StandingsPage = lazy(() => import('./StandingsPage'));
const StreamPage = lazy(() => import('./StreamPage'));
const LedgerPage = lazy(() => import('./LedgerPage'));
const AdminPage = lazy(() => import('./AdminPage'));
const FantasyPage = lazy(() => import('./FantasyPage'));


const theme = extendTheme({
  components: {
    Popover: {
      variants: {
        responsive: {
          content: { width: "unset" },
        },
      },
    },
  },
});



function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <UserContextWrapper>
          <AppStateWrapper>
            <Flex direction="column" w="100%">
              <LoginBar />
              <PossibleOutcomesModal />
              <Routes>
                <Route path="/rules" element={<Suspense><RulesPage /></Suspense>} />
                <Route path="/picks" element={<Suspense><PicksPage /></Suspense>} />
                <Route path="/standings" element={<Suspense><StandingsPage /></Suspense>} />
                <Route path="/stream" element={<Suspense><StreamPage /></Suspense>} />
                <Route path="/ledger" element={<Suspense><LedgerPage /></Suspense>} />
                <Route path="/admin" element={<Suspense><AdminPage /></Suspense>} />
                <Route path="/fantasy" element={<Suspense><FantasyPage /></Suspense> } />
                <Route path="*" element={<Navigate to="/rules" />} />
              </Routes>
            </Flex>
            <AppDrawer />
          </AppStateWrapper>
        </UserContextWrapper>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
