import React from 'react';
import { Bovada, dbNameMap } from '@chad/rugby-shared';
import { Image, ImageProps } from '@chakra-ui/react';

export interface TeamImgProps extends ImageProps {
  teamName: Bovada.CompetitorName;
}

function TeamImg({teamName, ...imgProps}: TeamImgProps ) {
  const props = {
    ...imgProps,
    src: `/teamLogos/${dbNameMap[teamName].logoName}.svg`,
  };
  return <Image {...props} />;
}

export default TeamImg;