import { Avatar, Box, Button, Checkbox, FormControl, HStack, Icon, Input, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Tooltip, useBoolean, useToast } from "@chakra-ui/react";
import React, { ChangeEventHandler, MutableRefObject, useEffect, useRef, useState } from "react";
import { saveProfile } from "../db";
import { useAppState } from "../hooks";
import { UserType } from "./UserContextWrapper";
import { GiCrenelCrown } from 'react-icons/gi';

function findPhotoUrl(user: UserType): string | undefined {
  if(!user) return undefined;
  
  return user.providerData.find((userInfo) => userInfo.photoURL)?.photoURL ?? undefined;
}

export interface ProfileProps{
  andThen?: () => unknown;
}

const highStakeIndicator =(ref: MutableRefObject<HTMLElement | null>) => (
  <Portal containerRef={ref}>
    <Tooltip textTransform="none" label="High Stakes" shouldWrapChildren={true}>
      <Icon position="absolute" left="-.25rem" top="-3.25rem" width="3.5rem" fontSize="6rem" color="yellow.600" as={GiCrenelCrown} />
    </Tooltip>
  </Portal>
);

export default function Profile(props: ProfileProps) {
  const { user, profile } = useAppState();
  const [name, setName] = useState(profile?.name ?? user?.displayName ?? '');
  const [avatarUrl, setAvatarUrl] = useState(profile?.avatarUrl ?? findPhotoUrl(user));
  const [saving, setSaving ] = useBoolean(false);
  const [agreeFlag, setAgreeFlag] = useBoolean(profile?.agreeFlag ?? false);
  const [highStakes, setHighstakes] = useBoolean(profile?.highStakes ?? false);
  const ref = useRef<HTMLDivElement|null>(null);
  const toast = useToast();
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (event) => setName(event.target.value);
  const handleAvatarUrlChange: ChangeEventHandler<HTMLInputElement> = (event) => setAvatarUrl(event.target.value);
  const handleAgreeChecked: ChangeEventHandler<HTMLInputElement> = (event) => event.target.checked ? setAgreeFlag.on() : setAgreeFlag.off();
  useEffect(() => {
    setName((oldName) => profile?.name ?? user?.displayName ?? oldName);
    setAvatarUrl(profile?.avatarUrl ?? findPhotoUrl(user));
  }, [profile, user]);

  const then = props.andThen || (() => null);
  const saveClick = () => {
    setSaving.on();
    saveProfile({ name, avatarUrl, agreeFlag, highStakes })
      .then(() => {
        toast({
          title: 'Profile Saved',
          description: 'We gotcha',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .then(then)
      .catch((e) => {
        toast({
          title: 'RIP',
          description: e?.message ?? 'Something went wrong',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

      })
      .then(() => setSaving.off());
  };
  return (
    <FormControl p={4}>
      <HStack mb={4} >
        <Popover>
          <PopoverTrigger>
            <Box>
              <Avatar ref={ref} cursor="pointer" name={name} src={avatarUrl} />
              {highStakes ? highStakeIndicator(ref) : <></>}
            </Box>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Input w="md" value={avatarUrl} placeholder="IMG url" onChange={handleAvatarUrlChange} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Input isInvalid={name.length > 30} value={name} onChange={handleNameChange} placeholder='name' />
      </HStack>
      <Checkbox display="block" isChecked={highStakes} onChange={setHighstakes.toggle}>High Stakes</Checkbox>
      <Checkbox isDisabled={profile?.agreeFlag ?? false} w="100%" display="block" isChecked={agreeFlag} onChange={handleAgreeChecked}>I&apos;m In!</Checkbox>
      <Button mt={4} isLoading={saving} onClick={saveClick} colorScheme="blue">Save</Button>
    </FormControl>
  );
}