import { DocumentReference } from "firebase/firestore";
import React, { createContext, Reducer, useEffect, useMemo, useReducer, useState } from "react";
import { ReactNode } from "react";
import { GameInfo, CurrentWeekWeb, Ledger, LedgerEntry, ActiveGame, StandingsRow, Selection, Bovada, LastWeekWeb, buildPayouts, AmountOption, AmountSelection, Profile, TeamSelection, UserLedgerResponse } from "@chad/rugby-shared";
import { Action, isLastWeekRequestedAction, isReceivedCurrentWeekAction, isReceivedGamesAction, isReceivedLedgerAction, isReceivedPicksAction, isReceivedProfilesAction, isRecevedLastWeekAction, isSelectAmountAction, isSelectTeamAction, isShowPossibleOutcomesAction, isToggleDrawerAction, isUserChangeAction, isUserLedgerReceivedAction, lastWeekRequested, receivedCurrentWeekData, receivedGames, receivedLastWeek, receivedLedger, receivedPicks, receivedProfiles, selectAmount, selectTeam, showPossibleOutcomes, toggleDrawer, userChange, userLedgerReceived } from "../actions";
import { getLastWeek, subscribeToCurrentWeek, subscribeToGameUpdates, subscribeToLedger, subscribeToPicks, subscribeToProfiles } from "../db";
import { useUser } from "../hooks";
import { dbGameInfoConverter } from "../utils";
import { PossibleOutcome } from "./OutcomeOddsTable";
import { UserType } from "./UserContextWrapper";

export interface AppState {
  drawerOpen: boolean;
  games?: Record<string, GameInfo>;
  selections: Record<string, Selection>;
  picks: Record<string, Selection>;
  user: UserType;
  profile?: Profile;
  profiles?: Record<string, Profile>;
  currentWeek?: CurrentWeekWeb;
  ledger: Ledger;
  lastWeek?: CurrentWeekWeb;
  lastWeekRequested?: DocumentReference<LastWeekWeb>;
  userLedgers: Record<string, Record<string, LedgerEntry>>;
  posibleOutcomes?: PossibleOutcome[];
}

export const defaultAppState: AppState = {
  drawerOpen: false,
  selections: {},
  picks: {},
  user: undefined,
  ledger: {},
  userLedgers: {},
};

export const AppStateContext = createContext<AppState>(defaultAppState);


const reducer:Reducer<AppState, Action> = (state, action): AppState => {

  if(isShowPossibleOutcomesAction(action)) {
    return {
      ...state,
      posibleOutcomes: action.payload,
    };
  }

  if(isUserLedgerReceivedAction(action)) {
    return {
      ...state,
      userLedgers: {
        ...state.userLedgers,
        [action.payload.uid]: action.payload.userLedger,
      },
    };
  }

  if(isLastWeekRequestedAction(action)) {
    return {
      ...state,
      lastWeekRequested: state?.currentWeek?.lastWeek,
    };
  }

  if(isRecevedLastWeekAction(action)) {
    const lastWeek = action.payload;
    determineHighestPossibleFinish({
      ...lastWeek,
      highStakesUsers: lastWeek.highStakesUsers ?? [],
    });
    return {
      ...state,
      lastWeek: action.payload,
    };
  }

  if(isReceivedLedgerAction(action)) {
    return {
      ...state,
      ledger: action.payload,
    };
  }

  if(isReceivedCurrentWeekAction(action)) {
    const currentWeek = action.payload;
    if(currentWeek?.standings && currentWeek?.games && currentWeek?.highStakesUsers) determineHighestPossibleFinish(currentWeek as WeekWithGamesAndStandings);
    
    return {
      ...state,
      currentWeek: action.payload,
    };
  }

  if(isReceivedProfilesAction(action)) {
    const profiles = action.payload;
    const user = state.user;
    const profile = user ? profiles?.[user.uid] : undefined;
    return {
      ...state,
      profile,
      profiles,
    };
  }

  if(isUserChangeAction(action)) {
    const user = action.payload;
    const profile = user ? state?.profiles?.[user.uid] : undefined;
    return {
      ...state,
      user,
      profile,
    };
  }

  if(isToggleDrawerAction(action)) {
    const open = action.payload ?? !state.drawerOpen;
    return {
      ...state,
      drawerOpen: open,
    };
  }

  if(isReceivedGamesAction(action)) {
    const games = action.payload
      .map(dbGameInfoConverter)
      .map(game => ({
        ...game,
        selection: state.selections[game.eventId],
      }))
      .reduce<Record<string, GameInfo>>((gameRecord, game) => ({ ... gameRecord, [game.eventId]: game}), {});
    return {
      ...state,
      games,
    };
  }

  if(isSelectTeamAction(action)) {
    const { eventId, team } = action.payload;
    let selection = state.selections[eventId] ?? {};
    selection = { ...selection, team };
    const selections = {
      ...state.selections,
      [eventId]: selection,
    };
    const game = state?.games?.[eventId] && {
      ...state.games[eventId],
      selection,
    };

    const games = game
      ? { ...state.games, [eventId]: game }
      : state.games;
    
    return {
      ...state,
      selections,
      games,
    };
  }

  if(isSelectAmountAction(action)) {
    const { eventId, amount } = action.payload;
    let selection = state.selections[eventId] ?? {};
    selection = { ...selection, amount };
    const selections = {
      ...state.selections,
      [eventId]: selection,
    };
    const game = state?.games?.[eventId] && {
      ...state.games[eventId],
      selection,
    };

    const games = game
      ? { ...state.games, [eventId]: game }
      : state.games;
    
    return {
      ...state,
      selections,
      games,
    };

  }

  if(isReceivedPicksAction(action)) {
    const picks = action.payload;
    const selections = {
      ...state.selections,
      ...picks,
    };
    const games = Object.values(state.games ?? {})
      .map(game => ({
        ...game,
        selection: selections[game.eventId],
      }))
      .reduce<Record<string, GameInfo>>((gameRecord, game) => ({ ... gameRecord, [game.eventId]: game}), {});
    return {
      ...state,
      selections,
      games,
      picks,
    };
  }

  return state;
};

export type AppActions = {
  toggleDrawer: (open?: boolean) => void,
  selectTeam: (selection: TeamSelection) => void
  selectAmount: (selection: AmountSelection) => void
  requestLastWeek: () => void,
  userLedgerReceived: (response: UserLedgerResponse) => void,
  showPossibleOutcomes: (outcomes?: PossibleOutcome[]) => void,
}

export const AppActionsContext = createContext<AppActions>({
  toggleDrawer: () => null,
  selectTeam: () => null,
  selectAmount: () => null,
  requestLastWeek: () => null,
  userLedgerReceived: () => null,
  showPossibleOutcomes: () => null,
});

type Props = {
  children?: ReactNode;
};

export const TimerContext = createContext<number>(Date.now());

export default function AppStateWrapper({ children }: Props) {
  const user = useUser();
  const [state, dispatch] = useReducer(reducer, defaultAppState);

  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    const timer = setInterval(() => setNow(Date.now()), 1000);
    return () => clearInterval(timer);
  },[]);
  
  useEffect(() => {
    return subscribeToGameUpdates((games) => receivedGames(dispatch, games));
  },[]);

  useEffect(() => {
    userChange(dispatch, user);
    if(!user)
      return;
    const unsubFromPicks = subscribeToPicks((picks) => receivedPicks(dispatch, picks));
    const unsubFromProfiles = subscribeToProfiles((profiles) => receivedProfiles(dispatch, profiles));
    const unsubFromCurrentWeek = subscribeToCurrentWeek((currentWeek) => receivedCurrentWeekData(dispatch, currentWeek));
    const unsubFromLedger = subscribeToLedger((ledger) => receivedLedger(dispatch, ledger));
    return () => {
      unsubFromPicks();
      unsubFromProfiles();
      unsubFromCurrentWeek();
      unsubFromLedger();
    };
  },[user]);

  useEffect(() => {
    if(state.lastWeekRequested) {
      getLastWeek(state.lastWeekRequested)
        .then(lastWeek => {
          if(lastWeek) receivedLastWeek(dispatch, lastWeek);
        });
    }

  },[state.lastWeekRequested]);

  const appActions = useMemo<AppActions>(() => ({
    showPossibleOutcomes: (outcomes?: PossibleOutcome[]) => showPossibleOutcomes(dispatch, outcomes),
    toggleDrawer: (open?: boolean) => toggleDrawer(dispatch, open),
    selectTeam: (selection: TeamSelection) => selectTeam(dispatch, selection),
    selectAmount: (selection: AmountSelection) => selectAmount(dispatch, selection),
    requestLastWeek: () => lastWeekRequested(dispatch, undefined),
    userLedgerReceived: (response: UserLedgerResponse) => userLedgerReceived(dispatch, response),
  }), []);
  return (
    <AppStateContext.Provider value={state}>
      <AppActionsContext.Provider value={appActions}>
        <TimerContext.Provider value={now}>
          {children}
        </TimerContext.Provider>
      </AppActionsContext.Provider>
    </AppStateContext.Provider>
  );
}

export interface PossibleStandingsRow extends StandingsRow { bestPossible?: number, worstPossible?: number, expectedPayout?: number, outcomes?: PossibleOutcome[]}

interface WeekWithGamesAndStandings extends CurrentWeekWeb {
  games: ActiveGame[];
  standings: Record<string, PossibleStandingsRow>;
  highStakesUsers: string[];
}

function determineHighestPossibleFinish({games, standings, highStakesUsers}: WeekWithGamesAndStandings) {
  const possibleRemainingWinners = games
    .filter(g => g.status !== 'FINAL')
    .reduce<Array<Array<Bovada.CompetitorName>>>((arr, game) => {

      return arr.flatMap(teamArr => {
        return [[...teamArr, game.home.name], [...teamArr, game.away.name]];
      });
    }, [[]])
    .map(arr => new Set(arr));

  const odds = games.reduce<Record<string,number>>((odds, game) => {
    odds[game.away.name] = game.away.odds;
    odds[game.home.name] = game.home.odds;
    return odds;
  }, {});
  const probs = games.reduce<Record<string,number>>((probs, game) => {
    if (game.away.winChance) probs[game.away.name] = game.away.winChance;
    if (game.home.winChance) probs[game.home.name] = game.home.winChance;
    return probs;
  }, {});
  const rows = Object.values(standings);
  const outcomesMap:Record<string, PossibleOutcome[]> = {};
  possibleRemainingWinners.forEach(winnersSet => {
    const winners = Array.from(winnersSet);
    const outcomeProbs = winners.map(winner => probs[winner]).reduce((a,b) => a * b, 1);
    
    const simulatedStandings:SimulatedRow[] = simulateStandings(winnersSet, rows, odds);
    const payouts = buildPayouts(simulatedStandings, "score", "uid", new Set(highStakesUsers));
    simulatedStandings
      .forEach((row) => {
        const record = payouts.get(row);
        const finish = record?.total ?? 0;

        const outcomes = outcomesMap[row.uid] ?? [];
        outcomes.push({winners, prob: outcomeProbs, payout: finish});
        outcomesMap[row.uid] = outcomes;

      });
  });

  rows.forEach(row => {
    const outcomes = outcomesMap[row.uid];
    row.bestPossible = outcomes?.reduce((a,b) => a.payout > b.payout ? a : b)?.payout;
    row.worstPossible = outcomes?.reduce((a,b) => a.payout < b.payout ? a : b)?.payout;
    row.expectedPayout = outcomes?.map(({prob, payout}) => prob * payout)?.reduce((a,b) => a+b, 0);
    row.outcomes = outcomes;
  });
}

interface SimulatedRow { uid: string, score: number}

function simulateStandings(winners: Set<Bovada.CompetitorName>, rows: StandingsRow[], odds: Record<string, number>): SimulatedRow[] {
  return rows.map(row => {
    const score = row.games.map(game => {
      if(game.result === 'In Progress' || game.result === 'Not Started') {
        if(winners.has(game.team)) {
          const thisOdds = odds[game.team];
          const winValue = thisOdds > 0
            ? thisOdds * positiveOddsMultipliers[game.amount]
            : 100 * Number(game.amount) / Math.abs(thisOdds);
          return winValue;
        }
        else {
          return Number(game.amount) * -1;
        }
      }
      else {
        return game.value;
      }
    }).reduce<number>((a,b) => a + b, 0);
    return {uid: row.uid, score };
  });
}

const positiveOddsMultipliers: Record<AmountOption, number> = {
  "400": 4,
  "200": 2,
  "100": 1,
};