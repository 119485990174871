import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure } from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';
import { useAppActions, useAppState } from '../hooks';
import OutcomeOddsTable from './OutcomeOddsTable';

export default function PossibleOutcomesModal() {
  const { posibleOutcomes } = useAppState();
  const { showPossibleOutcomes } = useAppActions();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const clearAndClose = useCallback(() => {
    showPossibleOutcomes(undefined);
    onClose();
  }, [showPossibleOutcomes, onClose]);

  useEffect(() => {
    if(posibleOutcomes) onOpen();
  }, [posibleOutcomes, onOpen]);

  const body = posibleOutcomes ? <OutcomeOddsTable outcomes={posibleOutcomes} /> : <></>;
  return (
    <Modal
      isOpen={isOpen}
      onClose={clearAndClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Expectation Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>
      </ModalContent>
    </Modal>
  );
}