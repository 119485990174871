import { Dispatch } from "react";
import { AmountSelection, CurrentWeekWeb, Game, LastWeekWeb, Ledger, Profile, Selection, TeamSelection, UserLedgerResponse } from "@chad/rugby-shared";
import { PossibleOutcome } from "./components/OutcomeOddsTable";
import { UserType } from "./components/UserContextWrapper";

export interface Action {
  type: symbol,
  payload?: unknown
}

function buildAction<T>(description: string) {
  const typeSymbol = Symbol(description);
  interface ActionInterface extends Action {
    type: typeof typeSymbol
    payload: T
  }
  const createAction = (payload: T): ActionInterface => ({type: typeSymbol, payload });
  const dispatchAction = (dispatch: Dispatch<Action>, payload: T) => dispatch(createAction(payload));
  const isAction = (action: Action): action is ActionInterface => action.type === typeSymbol;

  return [dispatchAction, isAction] as [typeof dispatchAction, typeof isAction];
}

export const [toggleDrawer, isToggleDrawerAction] = buildAction<boolean|undefined>('Toggle Drawer');

export const [receivedGames, isReceivedGamesAction] = buildAction<Game[]>('Received Games');

export const [receivedPicks, isReceivedPicksAction] = buildAction<Record<string, Selection>>('Received Picks');

export const [selectTeam, isSelectTeamAction] = buildAction<TeamSelection>('Select Team');

export const [selectAmount, isSelectAmountAction] = buildAction<AmountSelection>('Select Amount');

export const [userChange, isUserChangeAction] = buildAction<UserType>('User Update');

export const [receivedProfiles, isReceivedProfilesAction] = buildAction<Record<string, Profile>>("Received Profiles");

export const [receivedCurrentWeekData, isReceivedCurrentWeekAction] = buildAction<CurrentWeekWeb | undefined>("Received Current Week");

export const [receivedLedger, isReceivedLedgerAction] = buildAction<Ledger>("Received Ledger");

export const [receivedLastWeek, isRecevedLastWeekAction] = buildAction<LastWeekWeb>("Last Week Received");

export const [lastWeekRequested, isLastWeekRequestedAction] = buildAction<undefined>("Last Week Requested");

export const [userLedgerReceived, isUserLedgerReceivedAction] = buildAction<UserLedgerResponse>("User Ledger Received");

export const [showPossibleOutcomes, isShowPossibleOutcomesAction] = buildAction<PossibleOutcome[] | undefined>("Show Possible Outcomes");
