import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { auth } from "./constants";
import { Game, Team, Selection, dbNameMap, GameInfo, TeamInfo } from "@chad/rugby-shared";

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

export function login() {
  signInWithPopup(auth, provider);
}

export function logout() {
  signOut(auth);
}

export const dbTeamInfoConverter = ({name, odds, winChance}: Team): TeamInfo => ({
  ...dbNameMap[name],
  odds,
  winChance,
});

export const dbGameInfoConverter = ({home, away, link, id: eventId, startTime}: Game): GameInfo => ({
  home: dbTeamInfoConverter(home),
  away: dbTeamInfoConverter(away),
  link,
  eventId,
  startTime: startTime.toDate(),
});

export function validPicks(picks: Record<string,Selection>, games: GameInfo[]): { valid: boolean, problems: string[] } {
  const problems: string[] = [];
  const selections = Object.values(picks);
  const counts = selections.reduce((counts, selection) => {
    if(selection.amount) {
      counts[selection.amount] += 1;
    }
    return counts;
  }, { '400': 0, '200': 0, '100': 0 });

  Object.entries(counts)
    .filter(([,count]) => count > 2)
    .forEach(([amount, count]) => problems.push(
      `Select no more than 2 bets of each denomination. (You have ${count} bets of $${amount})`,
    ));

  if(games.length) {
    const earliestStart = games.length === 1
      ? games[0].startTime
      : games
        .map((g) => g.startTime)
        .reduce((a,b) => a < b ? a : b);
    const now = new Date();
    if(now > earliestStart) {
      problems.push('Games have already started.  Picks are locked.');
    }
  }
  
  return {
    valid: (problems.length === 0),
    problems,
  };
}

export function numberFormat(num: number): string {
  const base = Math.abs(num).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  return num < 0 ? `($${base})` : "$" + base;
  
}