import { useContext } from "react";
import { AppActionsContext, AppStateContext, TimerContext } from "./components/AppStateWrapper";
import { UserContext } from "./components/UserContextWrapper";

export function useUser() {
  return useContext(UserContext);
}

export function useAppState() {
  return useContext(AppStateContext);
}

export function useAppActions() {
  return useContext(AppActionsContext);
}

export function useTimer() {
  return useContext(TimerContext);
}
