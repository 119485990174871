import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';

const rootEl = document.getElementById('root');

if(rootEl === null) throw new Error("root element not found");

const root = ReactDOM.createRoot(rootEl);
const app = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
root.render(app);