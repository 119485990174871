// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA_0JTRskDxV5L9nnStSg60sh9vBqVWKtY",
  authDomain: "northamptonsaints.firebaseapp.com",
  projectId: "northamptonsaints",
  storageBucket: "northamptonsaints.appspot.com",
  messagingSenderId: "604654506954",
  appId: "1:604654506954:web:bb67e8a467872a1e80b533",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth();

export const db = getFirestore();

const functions = getFunctions();

export const getEmails = httpsCallable<unknown, string[]>(functions, 'email');