import React from "react";
import { Link, useMatch } from 'react-router-dom';
import { Button, ButtonGroup, Flex, IconButton, Show, Text } from '@chakra-ui/react';
import { useAppActions, useAppState } from "../hooks";
import UserBadge from "./UserBadge";
import { HamburgerIcon } from "@chakra-ui/icons";
import { login } from "../utils";

export default function LoginBar() {
  const onRules = useMatch('/rules');
  const onPicks = useMatch('/picks');
  const onStandings = useMatch('/standings');
  const onStream = useMatch('/stream');
  const onLedger = useMatch('/ledger');
  const onAdmin = useMatch('/admin');
  const onFantasy = useMatch('/fantasy');
  const {user} = useAppState();
  const {toggleDrawer} = useAppActions();
  const subComponent = user === null
    ? <Button colorScheme="whiteAlpha" onClick={login}>Login</Button>
    : user === undefined
      ? <></>
      : <UserBadge user={user} />;
  return (
    <Flex p={4} bgColor="gray.900" color="white" gap={4}>
      <IconButton colorScheme="whiteAlpha" icon={<HamburgerIcon />} aria-label={"Open Drawer"} onClick={() => toggleDrawer()}/>
      <Text fontSize="3xl">Rugby Pick&apos;Em</Text>
      <ButtonGroup flexGrow="1" isAttached={true} >
        <Show above="lg">
          <Button colorScheme={onRules ? 'blue' : 'whiteAlpha'} as={Link} to="/rules">Rules</Button>
          <Button colorScheme={onLedger ? 'blue' : 'whiteAlpha'} as={Link} to="/ledger">Ledger</Button>
          <Button colorScheme={onPicks ? 'blue' : 'whiteAlpha'} as={Link} to="/picks">Picks</Button>
          <Button colorScheme={onStandings ? 'blue': 'whiteAlpha'} as={Link} to="/standings">Standings</Button>
          <Button colorScheme={onFantasy ? 'blue': 'whiteAlpha'} as={Link} to="/fantasy">Fantasy</Button>
          <Button colorScheme={onStream ? 'blue' : 'whiteAlpha'} as={Link} to="/stream">Stream</Button>
          { (user?.email === 'edgethio@gmail.com')
            ? (<Button colorScheme={onAdmin ? 'blue' : 'whiteAlpha'} as={Link} to="/admin">Admin</Button>)
            : ''
          }
        </Show>
      </ButtonGroup>
      {subComponent}
    </Flex>
  );
}